<template>
  <ul :class="['social_list', alignment]">
    <li v-for="social in socials" :key="social.title">
      <a :href="social.link" target="_blank">
        <IconInstagram v-if="social.type === 'instagram'" />
        <IconTwitter v-if="social.type === 'twitter'" />
        <IconLinkedin v-if="social.type === 'linkedin'" />
        <IconGithub v-if="social.type === 'github'" />
        <IconEmail v-if="social.type === 'email'" />
        <IconEthereum v-if="social.type === 'ethereum'" />
      </a>
    </li>
  </ul>
</template>

<script>
  import IconInstagram from "../atoms/IconInstagram";
  import IconTwitter from "../atoms/IconTwitter";
  import IconLinkedin from "../atoms/IconLinkedin";
  import IconGithub from "../atoms/IconGithub";
  import IconEmail from "../atoms/IconEmail";
  import IconEthereum from "../atoms/IconEthereum";

  export default {
    name: "SocialList",
    props: {
      socials: Array,
      alignment: String,
    },
    components: {
      IconInstagram,
      IconTwitter,
      IconLinkedin,
      IconGithub,
      IconEmail,
      IconEthereum,
    },
  };
</script>

<style scoped lang="scss">
  ul.social_list {
    margin: 0;
  }

  ul.social_list li {
    display: inline-block;
    margin: 0 10px;
    width: 20px;
  }

  ul.social_list.left li:first-child {
    margin: 0 10px 0 0;
  }

  ul.social_list li a {
    transition: all 0.3s ease-in-out;
  }

  ul.social_list li a:hover {
    opacity: 0.6;
  }

  ul.social_list.right {
    text-align: right;

    li:last-child {
      margin: 0 0 0 10px;
    }
  }
</style>
