var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"thumbnail_card_container"},[(_vm.isExternalLink)?_c('a',{staticClass:"thumbnail_card",class:[{ blur: _vm.isBlurred }, _vm.cardType],attrs:{"href":_vm.link,"target":"_blank"}},[_c('div',{staticClass:"thumbnail_card_image",class:[{ overlayed: _vm.isOverlayed }],style:({
        backgroundImage: 'url(' + _vm.image + ')',
        backgroundPosition: _vm.imageFocus,
        backgroundColor: _vm.color,
      })},[_c('h3',{domProps:{"innerHTML":_vm._s(_vm.title)}})]),(_vm.content)?_c('div',{staticClass:"thumbnail_card_content",domProps:{"innerHTML":_vm._s(_vm.content)}}):_vm._e()]):_c('router-link',{staticClass:"thumbnail_card",class:[{ blur: _vm.isBlurred }, _vm.cardType],attrs:{"to":_vm.link}},[_c('div',{staticClass:"thumbnail_card_image",class:[{ overlayed: _vm.isOverlayed }],style:({
        backgroundImage: 'url(' + _vm.image + ')',
        backgroundPosition: _vm.imageFocus,
        backgroundColor: _vm.color,
      })},[_c('h3',{domProps:{"innerHTML":_vm._s(_vm.title)}})]),(_vm.content)?_c('div',{staticClass:"thumbnail_card_content",domProps:{"innerHTML":_vm._s(_vm.content)}}):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }