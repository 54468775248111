import { render, staticRenderFns } from "./PageHeader.vue?vue&type=template&id=fae69082&scoped=true&"
import script from "./PageHeader.vue?vue&type=script&lang=js&"
export * from "./PageHeader.vue?vue&type=script&lang=js&"
import style0 from "./PageHeader.vue?vue&type=style&index=0&id=fae69082&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fae69082",
  null
  
)

export default component.exports