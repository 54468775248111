<template>
  <ul class="credit_list">
    <li v-for="credit in credits" :key="credit.title">
      <span class="title">{{ credit.title }}</span>
      <span v-html="credit.text" class="text"></span>
    </li>
  </ul>
</template>

<script>
  export default {
    name: "CreditList",
    props: {
      credits: Array,
    },
  };
</script>

<style scoped lang="scss">
  // Structure

  ul.credit_list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  ul.credit_list li {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    @media all and (max-width: 568px) {
      width: 100%;
    }
  }

  // Title

  ul.credit_list li .title {
    font-weight: bold;
    text-transform: uppercase;
    width: 50%;
  }

  // Text

  ul.credit_list li .text {
    width: 50%;
  }
</style>
