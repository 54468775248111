import Vue from "vue";
import App from "./App";
import store from "./store";
import router from "./router";
import { mapActions } from "vuex";
import VueAnalytics from "vue-analytics";

Vue.use(VueAnalytics, {
  id: "UA-36564362-1",
  router,
});

Vue.config.productionTip = false;

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
  methods: {
    ...mapActions("content", ["getInitialData"]),
  },
  mounted: function () {
    this.getInitialData();
  },
}).$mount("#app");
