<template>
  <div
    class="footer"
    v-if="!loading"
    :class="[{ nav_open: navIsOpen }, colorScheme]"
  >
    <div class="contact">
      <p>
        <a :href="`mailto:${options.email}`">{{ options.email }}</a>
      </p>
      <span class="line"></span>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from "vuex";

  export default {
    name: "Footer",
    props: {
      loading: Boolean,
    },
    methods: {
      ...mapActions("settings", ["toggleNav"]),
    },
    computed: {
      ...mapState("settings", {
        colorScheme: (state) => state.colorScheme,
        navIsOpen: (state) => state.navIsOpen,
      }),
      ...mapState("content", {
        menus: (state) => state.menus,
        options: (state) => state.options,
      }),
    },
  };
</script>

<style scoped lang="scss">
  @import "../../styles/base/_variables.scss";
  @import "../../styles/base/_mixins.scss";

  /*==================================
  Contact
  ==================================*/

  .footer .contact {
    position: relative;
    text-align: center;
    display: none;

    @media all and (max-width: 768px) {
      display: block;
    }
  }

  .footer .contact p {
    color: #fff;
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    font-size: 12px;
    font-weight: 400;
    transition: all 0.3s ease-in-out;
  }

  .footer .contact span.line {
    width: 1px;
    height: 30px;
    margin: 0 auto;
    background: #fff;
    display: block;
    transition: all 0.3s ease-in-out;
  }

  .footer.light {
    .contact p {
      color: #000;
    }
    span.line {
      background: #000;
    }
  }
</style>
